/* global HTMLElement, window */

/**
* Web component for language switcher; redirects user to correct site
* when he changes the selected language.
*/
class LanguageSelector extends HTMLElement {

	constructor() {
		super();
		this._addChangeListener();
	}


	/**
	* Listens to changes on <select> element, redirects user correspondingly.
	*/
	_addChangeListener() {

        this.querySelector('select').addEventListener('change', (ev) => {

        	ev.preventDefault();
        	const url = ev.target.value;

        	window.location.href = url;

        });

	}

}

window.customElements.define('language-selector', LanguageSelector);